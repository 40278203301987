<template>
  <AdvancedSelect :items="items" :onSelect="onSelect">
    <input
      type="text"
      class="search form-input"
      :placeholder="$t('search.placeholder')"
      @keypress="search"
    />
  </AdvancedSelect>
</template>
<script setup lang="ts">
import AdvancedSelect from '~/components/ui/advancedSelect/advancedSelect.vue';
import { __debounce } from '@lotsof/sugar/function';
import type { TAdvancedSelectItem } from '../ui/advancedSelect/advancedSelect.type';

// dato search
import { models as __models } from '~/lib/dato/dato.js';

const localePath = useLocalePath();
const { locale } = useI18n();

let items: Ref<TAdvancedSelectItem[]> = ref([]);

const search = __debounce(300, async (e: KeyboardEvent) => {
  const value = (e.target as HTMLInputElement).value;

  const results = await __models.search.searchPages(value, locale.value);
  items.value = results.map((page) => {
    return {
      id: page.slug,
      label: page.title,
      value: page.slug,
    };
  });
});

const onSelect = (item: TAdvancedSelectItem): void => {
  if (item.value) {
    navigateTo(localePath(`/${item.value}`));
  }
};
</script>
