<template>
  <Header :menu="menuHeader ?? {}" :layout="layout" theme="inverted" />
  <slot />
  <Footer :menu="menuFooter ?? {}" />
</template>
<script lang="ts" setup>
import './main.css';

import { toHead } from 'vue-datocms';
import { models as __models } from '~/lib/dato/dato.js';
import Header from '~/components/header/header.vue';
import Footer from '~/components/footer/footer.vue';
import { computed } from 'vue';
const
  { locale } = useI18n();
const metasData = await __models.metas.getMetas();
const menuHeader = await __models.menus.getMenu('menu_header', locale.value),
  menuFooter = await __models.menus.getMenu('menu_footer', locale.value),
  layout = await __models.layout.getLayout('main');

useHead(() => {
  return toHead(metasData.metas?.faviconMetaTags || []);
});
</script>
