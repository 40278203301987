<template>
  <div class="advanced-select">
    <slot />
    <div :class="`advanced-select_dropdown ${!items.length ? '-empty' : ''}`">
      <ul class="advanced-select_items">
        <li
          class="advanced-select_item"
          v-for="item in <TAdvancedSelectItem[]>items"
          :key="(<TAdvancedSelectItem>item).id"
          @click="selectItem(item as TAdvancedSelectItem)"
          tabindex="0"
        >
          {{ typeof item.label === 'function' ? item.label(item) : item.label }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { TAdvancedSelect, TAdvancedSelectItem } from './advancedSelect.type';
import { withDefaults } from 'vue';

const props = withDefaults(defineProps<TAdvancedSelect>(), {
  items: () => [],
  onSelect: () => {}
});

function selectItem(item: TAdvancedSelectItem): void {
  props.onSelect(item);
}
</script>
